import localforage from 'localforage'

const getCitiesFromBackOffice = ({ commit}, { payload, context}) => {
  var string = payload ? payload : 'a';
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-city/` + string,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const getCountryState = ({ commit}, { payload, context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-country-state-by-city/` + payload.id,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const countryData = ({ commit, context}, query = 'some') => {

  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/countries/" + query).then((response) => {
      commit("SET_COUNTRY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });
};

const getCountryAndCountryCode = ({ commit}, {context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_ART_API_URL + `api/countries`,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const fetchCommonData = ({ commit}, {payload, context})=>{
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_ART_API_URL + 'api/get-common-data/'+payload.type+'?search=' +payload.search,
  }).then((res) => {
      return res.data;
  }).catch(e => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data
    return false
  });
};

export const getMasterDataFromBackOffice = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getMasterData').then((response) => {
      return response.data;
  }).catch ((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getUnitsFromBackOffice " + error, { root: true });
  });
}

export const getCategoryFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
  let searchQuery = payload.searchQuery;
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-master-data-categories/' + searchQuery).then((response) => {
      return response.data;
  }).catch ((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getCategoryFromBackOffice " + error, { root: true });
  });
}

export const getSubCategoryFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
  let searchQuery = payload.searchQuery;
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-master-data-sub-categories/' + searchQuery).then((response) => {
      return response.data;
  }).catch ((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getSubCategoryFromBackOffice " + error, { root: true });
  });
}


export const getFrameVendorFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
  let searchQuery = payload.searchQuery;
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-frame-vendor/' + searchQuery).then((response) => {
      return response.data;
  }).catch ((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getFrameVendorFromBackOffice " + error, { root: true });
  });
}
export const getCountryCode = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/phone-code').then((response) => {
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getCountryCode " + error, { root: true });
      return false;
  });
}
export const getSiteSettingsClient = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getSellerSettingsClient?user_id='+payload.user_id).then((response) => {
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong getSellerSettingsClient " + error, { root: true });
      return false;
  });
}
export const getSellerGeneralSettings= ({ commit, dispatch },{context, payload}) => {
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-client-settings?user_id='+payload.id).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return false;
  })
}
const languagesList = ({ commit, context}) => {
  
  return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL  + "api/languages-list").then((response) => {
      if(response.data.success){
        return response.data.data;
      }else{
        return false;
      }
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      return false;
    });   
};
export default {
	  countryData,
    getCountryAndCountryCode,
    getCitiesFromBackOffice,
    getCountryState,
    fetchCommonData,
    getMasterDataFromBackOffice,
    getCategoryFromBackOffice,
    getFrameVendorFromBackOffice,
    getSubCategoryFromBackOffice,
    getCountryCode,
    getSiteSettingsClient,
    getSellerGeneralSettings,
    languagesList
};
